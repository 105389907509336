<template>
  <div>
    <div class="row">
      <div class="col-md-2">
        <VerticalPills :items="tabs">
          <template #item="{ item }">
            <router-link class="nav-link" active-class="active" :to="{ name: item.route }">
              {{ item.name }}
            </router-link>
          </template>
        </VerticalPills>
      </div>
      <div class="col">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import VerticalPills from '@/components/base/VerticalPills';

export default {
  name: 'AdminGridfetchView',
  components: {
    VerticalPills
  },
  data() {
    return {
      tabs: [
        { name: 'LOAs', route: 'admin-gridfetch-loa', icon: 'fa-file-contract' },
        { name: 'Usage', route: 'admin-gridfetch-usage', icon: 'fa-tally' }
      ]
    };
  }
};
</script>
