var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-2"
  }, [_c('VerticalPills', {
    attrs: {
      "items": _vm.tabs
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('router-link', {
          staticClass: "nav-link",
          attrs: {
            "active-class": "active",
            "to": {
              name: item.route
            }
          }
        }, [_vm._v(" " + _vm._s(item.name) + " ")])];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col"
  }, [_c('router-view')], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }